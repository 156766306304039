const initialState = {
    user: {
        userName: null,
        PK: null,
        SK: null,
        bio: null,
        cashOut: null,
        contentUnLockUsers: null,
        countryCode: null,
        createdAt: null, 
        dob: null,
        earningsToDate: null, 
        fbFollowers: null, 
        firebaseUid: null, 
        hearts: null, 
        userId: null,
        instaFollowers: null,
        interests: null,
        name: null,
        phoneNumber: null,
        profilePicUrl: null,
        profileViewed: null,
        totalFollowers: null,
        totalFollowing: null,
        twitterFollowers: null,
        updatedAt: null,
        youtubeFollowers: null,
        ["GSI1-PK"]: null,
        privacyTag: null,
        pushToken: null,
        isDisable: null
    },
    showProfile: {
        show: false
    },
    purchasePopToggle: false,
    sendAllPopToggle: false,
    isLogIn: false,
    isDeviceGuest:false,
    isGuest: false,
    popUpWelcome: false,
    warningPopUp: false,
    error: "",
    loading: false,
    userNameCode: null,
    mobileNavbarToggle: false,
    settingBarToggle: false,
    inboxBarToggle: false,
    askedPermission: null,
    inboxCount: [],
    firstTime: null,
    userFollower: {
        id: null,
        name: null,
    },
    allConversations: [],
    conversations: [],
    conversationsFilter: [],
    statusScrollChatList: "",
    currentChat: {
        userId: null,
        userWithoutConversation: false,
        username: null,
        lastDoc: null,
        online: null,
        messages: [],
        privacyTag: null,
        participants: [],
        photoUrl: null,
    },
    isTyping: {
        userId: null,
        username: null,
        photoUrl: null,
    },
    currentConversationId: null,
    blockUsers: [],
    blockMeUsers: [],
    sendAllTog: false,
    dataDiscovery: [],
    dataFollowing: [],
    dataFollowers: [],
    dataInterest: [],
    isFollowing: true,
    scrollPosition: 0,
    mobileAppCashout: null,
    mobileAppHeart: null,
    mobileAppSuccess: null,
};

const rootReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'RESET_STATE':
            return initialState;
        case 'RESET_NOT_USER_STATE':
            return {
                ...state,
                user: {
                    ...state.user,
                    PK: null,
                    SK: null,
                    bio: null,
                    cashOut: null,
                    contentUnLockUsers: null,
                    countryCode: null,
                    createdAt: null, 
                    dob: null,
                    earningsToDate: null, 
                    fbFollowers: null, 
                    firebaseUid: null, 
                    hearts: null, 
                    instaFollowers: null,
                    interests: null,
                    name: null,
                    phoneNumber: null,
                    profilePicUrl: null,
                    profileViewed: null,
                    totalFollowers: null,
                    totalFollowing: null,
                    twitterFollowers: null,
                    updatedAt: null,
                    youtubeFollowers: null,
                    ["GSI1-PK"]: null,
                    privacyTag: null,
                    pushToken: null,
                    isDisable: null,
                },
                showProfile: {
                    show: false
                },
                purchasePopToggle: false,
                sendAllPopToggle: false,
                isLogIn: false,
                isDeviceGuest:false,
                isGuest: false,
                popUpWelcome: false,
                warningPopUp: false,
                error: "",
                loading: false,
                userNameCode: null,
                mobileNavbarToggle: false,
                settingBarToggle: false,
                inboxBarToggle: false,
                askedPermission: null,
                inboxCount: [],
                firstTime: null,
                userFollower: {
                    id: null,
                    name: null,
                },
                allConversations: [],
                conversations: [],
                conversationsFilter: [],
                statusScrollChatList: "",
                currentChat: {
                    userId: null,
                    userWithoutConversation: false,
                    username: null,
                    lastDoc: null,
                    online: null,
                    messages: [],
                    privacyTag: null,
                    participants: [],
                    photoUrl: null,
                },
                isTyping: {
                    userId: null,
                    username: null,
                    photoUrl: null,
                },
                currentConversationId: null,
                blockUsers: [],
                blockMeUsers: [],
                sendAllTog: false,
                dataDiscovery: [],
                dataFollowing: [],
                dataFollowers: [],
                dataInterest: [],
                isFollowing: true,
                scrollPosition: 0,
                mobileAppCashout: null,
                mobileAppHeart: null,
                mobileAppSuccess: null,
            };
        case 'DISCOVER_MODAL_PROFILE_STATE':
            return { 
                ...state, 
                showProfile: {
                    ...state.showProfile,
                    show: action.payload
                }
            };
        case 'POPUP_WELCOME_STATE':
            return { 
                ...state, 
                popUpWelcome: action.payload
            };
        case 'IS_GUEST_STATE':
            return { 
                ...state, 
                isGuest: action.payload
            };
        case 'DEVICE_GUEST_STATE':
            return { 
                ...state, 
                isDeviceGuest: action.payload
            };
        case 'DATA_GUEST_STATE':
            return { 
                ...state, 
                user: {
                    ...state.user,
                    bio: action.payload.bio,
                    cashOut: action.payload.cashOut,
                    contentUnLockUsers: action.payload.contentUnLockUsers,
                    countryCode: action.payload.countryCode,
                    createdAt: action.payload.createdAt,
                    dob: action.payload.dob,
                    earningsToDate: action.payload.earningsToDate,
                    fbFollowers: action.payload.fbFollowers,
                    firebaseUid: action.payload.firebaseUid,
                    hearts: action.payload.hearts,
                    userId: action.payload.userId,
                    instaFollowers: action.payload.instaFollowers,
                    interests: action.payload.interests,
                    name: action.payload.name,
                    phoneNumber: action.payload.phoneNumber,
                    profilePicUrl: action.payload.profilePicUrl,
                    profileViewed: action.payload.profileViewed,
                    totalFollowers: action.payload.totalFollowers,
                    totalFollowing: action.payload.totalFollowing,
                    twitterFollowers: action.payload.twitterFollowers,
                    updatedAt: action.payload.updatedAt,
                    userName: action.payload.userName,
                    youtubeFollowers: action.payload.youtubeFollowers,
                }
            };
        case 'IS_LOGIN_STATE':
            return { 
                ...state, 
                isLogIn: action.payload
            };
        case 'ERROR_LOGIN_STATE':
            return { 
                ...state, 
                error: action.payload,
                loading: false
            };
        case 'LOADING_LOGIN_STATE':
            return { 
                ...state, 
                loading: action.payload
            };
        case 'DATA_USER_STATE':
            return { 
                ...state, 
                user: {
                    ...state.user,
                    ["GSI1-PK"]: action.payload["GSI1-PK"],
                    PK: action.payload.PK,
                    SK: action.payload.SK,
                    bio: action.payload.bio,
                    cashOut: action.payload.cashOut,
                    contentUnLockUsers: action.payload.contentUnLockUsers,
                    countryCode: action.payload.countryCode,
                    createdAt: action.payload.createdAt,
                    dob: action.payload.dob,
                    earningsToDate: action.payload.earningsToDate,
                    fbFollowers: action.payload.fbFollowers,
                    firebaseUid: action.payload.firebaseUid,
                    hearts: action.payload.hearts,
                    userId: action.payload.userId,
                    instaFollowers: action.payload.instaFollowers,
                    interests: action.payload.interests,
                    name: action.payload.name,
                    phoneNumber: action.payload.phoneNumber,
                    profilePicUrl: action.payload.profilePicUrl,
                    privacyTag: action.payload.privacyTag,
                    profileViewed: action.payload.profileViewed,
                    totalFollowers: action.payload.totalFollowers,
                    totalFollowing: action.payload.totalFollowing,
                    twitterFollowers: action.payload.twitterFollowers,
                    updatedAt: action.payload.updatedAt,
                    userName: action.payload.userName,
                    youtubeFollowers: action.payload.youtubeFollowers,
                }
            };
        case 'USER_NAME_CODE_STATE':
            return { 
                ...state, 
                userNameCode: action.payload,
            };
        case 'MOBILE_NAVBAR_TOGGLE_STATE':
            return { 
                ...state, 
                mobileNavbarToggle: action.payload,
            };
        case 'DATA_USER_SIGNUP_STATE':
            return { 
                ...state, 
                user: {
                    ...state.user,
                    ["GSI1-PK"]: action.payload["GSI1-PK"],
                    bio: action.payload.bio,
                    cashOut: action.payload.cashOut,
                    contentUnLockUsers: action.payload.contentUnLockUsers,
                    countryCode: action.payload.countryCode,
                    createdAt: action.payload.createdAt,
                    dob: action.payload.dob,
                    earningsToDate: action.payload.earningsToDate,
                    fbFollowers: action.payload.fbFollowers,
                    firebaseUid: action.payload.firebaseUid,
                    hearts: action.payload.hearts,
                    userId: action.payload.userId,
                    instaFollowers: action.payload.instaFollowers,
                    interests: action.payload.interests,
                    name: action.payload.name,
                    phoneNumber: action.payload.phoneNumber,
                    profilePicUrl: action.payload.profilePicUrl,
                    profileViewed: action.payload.profileViewed,
                    totalFollowers: action.payload.totalFollowers,
                    totalFollowing: action.payload.totalFollowing,
                    twitterFollowers: action.payload.twitterFollowers,
                    updatedAt: action.payload.updatedAt,
                    userName: action.payload.userName,
                    youtubeFollowers: action.payload.youtubeFollowers,
                }
            };
        case 'SETTING_SIDEBAR_TOGGLE_STATE':
            return { 
                ...state, 
                settingBarToggle: action.payload,
            };
        case 'INBOXBAR_TOGGLE_STATE':
            return { 
                ...state, 
                inboxBarToggle: action.payload,
            };
        case 'REMOVE_PUSH_TOKEN_STATE':
            return { 
                ...state, 
                user: {
                    ...state.user,
                    pushToken: action.payload
                },
            };
        case 'UPDATE_ASK_PERMISSION_STATE':
            return { 
                ...state, 
                askedPermission: action.payload
            };
        case 'UPDATE_FIRST_TIME_STATE':
            return { 
                ...state, 
                firstTime: action.payload
            };
        case 'UPDATE_CONVERSATIONS_STATE':
            return { 
                ...state, 
                allConversations: action.payload.allConversations,
                conversations: action.payload.conversations,
                ...(action.payload.statusScrollChatList !== undefined ? { statusScrollChatList: action.payload.statusScrollChatList } : {}),
            };
        case 'UPDATE_TYPING_STATE':
            return { 
                ...state,
                isTyping: {
                    ...state.isTyping,
                    userId: action.payload.userId,
                    username: action.payload.username,
                    photoUrl: action.payload.photoUrl,
                }
            };
        case 'UPDATE_CURRENT_PARTICIPANTS_STATE':
            return { 
                ...state,
                currentChat: {
                    ...state.currentChat,
                    participants: action.payload,
                }
            };
        case 'INBOX_COUNT_STATE':
            return { 
                ...state,
                inboxCount: action.payload
            };
        case 'UPDATE_USER_DATA_NAVBAR_STATE':
            return { 
                ...state,
                user: {
                    ...state.user,
                    hearts: action.payload.hearts,
                    totalFollowers: action.payload.totalFollowers,
                    profilePicUrl: action.payload.profilePicUrl,
                    isDisable: action.payload.isDisable,
                    privacyTag: action.payload.privacyTag,
                    name: action.payload.name,
                    userName: action.payload.userName,
                    phoneNumber: action.payload.phoneNumber,
                    bio: action.payload.bio,
                }
            };
        case 'UPDATE_SEND_ALL_TOGGLE_STATE':
            return { 
                ...state,
                sendAllPopToggle: action.payload
            };
        case 'SEARCH_CONVERSATIONS_STATE':
            return { 
                ...state,
                conversationsFilter: action.payload
            };
        case 'SEARCH_CONVERSATIONS_STATE_CLEAN':
            return { 
                ...state,
                conversationsFilter: []
            };
        case 'CLEAN_CURRENT_CHAT_STATE':
            return { 
                ...state,
                currentChat: {
                    userId: null,
                    userWithoutConversation: false,
                    username: null,
                    lastDoc: null,
                    online: null,
                    messages: [],
                    privacyTag: null,
                    participants: [],
                    photoUrl: null,
                }
            };
        case 'UPDATE_CURRENT_CONVERSATION_ID_STATE':
            return { 
                ...state,
                currentConversationId: action.payload
            };
        case 'UPDATE_CURRENT_CHAT_USER_ID_STATE':
            return { 
                ...state,
                currentChat: {
                    ...state.currentChat,
                    userId: action.payload,
                }
            };
        case 'UPDATE_CURRENT_CHAT_DATA_STATE':
            return { 
                ...state,
                currentChat: {
                    ...state.currentChat,
                    online: action.payload.online,
                    username: action.payload.username,
                    userId: action.payload.userId,
                    privacyTag: action.payload.privacyTag,
                    photoUrl: action.payload.photoUrl,
                    userWithoutConversation: action.payload.userWithoutConversation,
                    name: action.payload.name,
                    isDisable: action.payload.isDisable,
                    isGuest: action.payload.isGuest,
                }
            };
        case 'UPDATE_BLOCK_USERS_DATA_STATE':
            return { 
                ...state,
                blockUsers: action.payload
            };
        case 'UPDATE_USERS_BLOCK_ME_STATE':
            return { 
                ...state,
                blockMeUsers: action.payload
            };
        case 'UPDATE_PURCHASE_POP_TOGGLE_STATE':
            return { 
                ...state,
                purchasePopToggle: action.payload
            };
        case 'UPDATE_USER_HEARTS_STATE':
            return { 
                ...state,
                user: {
                    ...state.user,
                    hearts: action.payload
                }
            };
        case 'UPDATE_CURRENT_MESSAGES_STATE':
            return { 
                ...state,
                currentChat: {
                    ...state.currentChat,
                    messages: action.payload
                }
            };
        case 'UPDATE_CURRENT_LASTDOC_STATE':
            return { 
                ...state,
                currentChat: {
                    ...state.currentChat,
                    lastDoc: action.payload
                }
            };
        case 'UPDATE_CURRENT_WITHOUT_CONVERSATION_STATE':
            return { 
                ...state,
                currentChat: {
                    ...state.currentChat,
                    userWithoutConversation: action.payload
                }
            };
        case 'UPDATE_SEND_ALL_TOG_STATE':
            return { 
                ...state,
                sendAllTog: action.payload
            };
        case 'UPDATE_DATA_DISCOVERY_STATE':
            return { 
                ...state,
                dataDiscovery: [...state.dataDiscovery, ...action.payload]
            };
        case 'UPDATE_DATA_DISCOVERY_FOLLOW_STATE':
            return { 
                ...state,
                dataDiscovery: [...action.payload]
            };
        case 'CLEAN_DATA_DISCOVERY_STATE':
            return { 
                ...state,
                dataDiscovery: []
            };
        case 'UPDATE_DISCOVER_MODAL_PROFILE_STATE':
            return { 
                ...state, 
                showProfile: action.payload
            };
        case 'UPDATE_DATA_FOLLOWING_STATE':
            return { 
                ...state, 
                dataFollowing: action.payload
            };
        case 'UPDATE_IS_FOLLOWING_STATE':
            return { 
                ...state, 
                isFollowing: action.payload
            };
        case 'UPDATE_DISCOVERY_DATA_STATE':
            return { 
                ...state,
                dataDiscovery: action.payload
            };
        case 'UPDATE_DATA_INTEREST_STATE':
            return { 
                ...state, 
                dataInterest: action.payload
            };
        case 'CLEAN_DATA_FOLLOWERS_STATE':
            return { 
                ...state,
                dataFollowers: []
            };
        case 'UPDATE_FOLLOWERS_STATE':
            return { 
                ...state, 
                dataFollowers: action.payload
            };
        case 'UPDATE_INTEREST_PAGE_STATE':
            return { 
                ...state,
                dataInterest: [...state.dataInterest, ...action.payload]
            };
        case 'CLEAN_INTEREST_PAGE_STATE':
            return { 
                ...state,
                dataInterest: []
            };
        case 'UPDATE_SCROLL_POSITION_STATE':
            return { 
                ...state,
                scrollPosition: action.payload
            };
        case 'UPDATE_USER_FOLLOWER_STATE':
            return { 
                ...state,
                userFollower: action.payload
            };
        case 'UPDATE_USER_PROFILE_STATE':
            return { 
                ...state,
                user: {
                    ...state.user,
                    profilePicUrl: action.payload
                } 
            };
        case 'UPDATE_USER_PROFILE_DATA_STATE':
            return { 
                ...state,
                user: {
                    ...state.user,
                    bio: action.payload.bio,
                    name: action.payload.name,
                    userName: action.payload.userName
                } 
            };
        case 'UPDATE_USER_PHONE_NUMBER_DATA_STATE':
            return { 
                ...state,
                user: {
                    ...state.user,
                    phoneNumber: action.payload,
                } 
            };
        case 'UPDATE_MOBILE_APP_CASHOUT_STATE':
            return { 
                ...state,
                mobileAppCashout: action.payload
            };
        case 'UPDATE_MOBILE_APP_HEART_STATE':
            return { 
                ...state,
                mobileAppHeart: action.payload
            };
        case 'UPDATE_MOBILE_APP_SUCCESS_STATE':
            return { 
                ...state,
                mobileAppSuccess: action.payload
            };
        case 'UPDATE_USER_DATA_INTEREST_STATE':
            return { 
                ...state,
                user: {
                    ...state.user,
                    interests: action.payload
                }
            };
        case 'UPDATE_USER_DATA_PRIVACYTAG_STATE':
            return { 
                ...state,
                user: {
                    ...state.user,
                    privacyTag: action.payload
                }
            };
        default:
            return state;
    }
};

export default rootReducer;