import warningIcon from "../../assets/icons/warning_Icon.png";

function InappropiatePopUp({ setShowWarning }) {

    const onClose = () => {
        setShowWarning(false);
        localStorage.setItem("warningPopUp", false);
    };


  return (
    <div className="fixed inset-x-0 bottom-0 z-50 sm:inset-0 sm:overflow-y-auto sm:z-10">
        <div className="sm:flex sm:items-center sm:justify-center sm:min-h-screen">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-40 backdrop-blur-[2px] transition-opacity"></div>
      <div
        className={`translate-y-0 w-full sm:w-auto sm:px-8 h-72 bg-white shadow-xl rounded-3xl transition-transform duration-300 ease-in-out`}
        style={{ border: '1px solid #b8b7b7'}}
      >
        <div className="flex justify-between items-center rounded-t-3xl p-4">
          <div className="w-14 h-14 flex justify-center items-center mx-auto">
            <img
              src={warningIcon}
              alt="Bell Notifications"
              className="object-contain flex"
            />
          </div>
        </div>
        <div className="flex justify-center h-full">
          <div className="text-center">
            <p className="pb-2 text-sm text-[#696969]">
                Posting inappropiate content or profile
              <br/>
                pictures will lead to immediate removal and
              <br/>
                possible account suspension. Respect our app
              <br/>
                community guidelines.
            </p>
            <p className="font-bold text-lg pb-4 text-[#6eb5ed]">
              Thank you.
            </p>
        <button
            className={`profile-save-btn bg-[#58a1f5] disabled:opacity-75
            border border-[#58a1f5] text-btn-save-profile rounded-full
            text-white font-bold cursor-pointer px-[4rem] py-[.6rem]
            transition duration-300 ease-in-out hover:bg-white hover:text-[#58a1f5]
            }`}
            onClick={onClose}
        >
            <p className="text-sm">
            Understood
            </p>
        </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};


export default InappropiatePopUp;