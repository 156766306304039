import Bowser from "bowser";
import "../../styles/SendAllModal.scss";
import { Url } from "../../axios/authAPI";
import GifModal from "../InboxPage/GifModal";
import { useAxios } from "../../axios/authAPI";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import LargeSizePopUp from "../PopUp/LargeSizePopUp";
import { storage } from "../../Config/configurations";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import UpladGifSVG from "../../assets/icons/gifNewIcon.png";
import { CircularProgressbar } from "react-circular-progressbar";
import { EmojiPicker } from "../chatTool/emojiPicker/EmojiPicker";
import GuestToastBar from "../../components/GuestComponents/Toast/Toast";
import warning from "../../assets/icons/warning_Icon2.png";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  UpdateSendAllToggleState,
  UpdateOnlineUser,
  UpdateSendAllTogState,
  ResetNotUserState,
  UpdateIsGuestState,
} from "../../redux/actions/index";

function SendAllModal() {
  let api = useAxios();
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [type, setType] = useState(0);
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState();
  const [gifImg, setGifImg] = useState("");
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [packages, setPackages] = useState([]);
  const [loadGif, setLoadGid] = useState(false);
  const [gifHeight, setGifHeight] = useState(1);
  const [contentUrl, setContentUrl] = useState("");
  const [heartActive, setHeartActive] = useState({
    noOfHearts: 0,
    pricePlan: "Free",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [sendMessage, setSendMessage] = useState("");
  const [uploadBlur, setUploadBlur] = useState(false);
  const [withOutFileName, setWithOutFileName] = useState(true);
  const [showGrid, toggleGrid] = useState(() => false);
  const [gifConfirmation, setGifConfirmation] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [hidePackage, setHidePackage] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [uploadLoader, setUploadLoader] = useState(false);
  const [fileLargeError, setFileLargeError] = useState(false);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [disabledButtonSend, setDisabledButtonSend] = useState(true);
  const [showGuestPopUp, setShowGuestPopUp] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [uploadTask, setUploadTask] = useState(null);
  const [networkStatus, setNetworkStatus] = useState("online");
  const toggleGifGrid = () => toggleGrid((prev) => !prev);

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const inputRef = useRef(null)

  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
  || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1;

  useEffect(() => {
    if (!selectedFile) {
      setType(0);
    }
    setDisabledButtonSend(!selectedFile);
  }, [selectedFile]);

  const browser = Bowser.getParser(window.navigator.userAgent);
  var video = document.createElement("video");
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const type0 = "Sent to all followers 😊 🖋️";
  const type12 = "Sent to all followers 😊 📸";
  const type3 = "Sent to all followers 😊 GIF";


  /* Change online status */
  window.addEventListener("online", () => {
    setNetworkStatus("online");
  });

  /* Change offline status */
  window.addEventListener("offline", () => {
    setNetworkStatus("offline");
  });
  console.log("networkStatus---", networkStatus);
  const handleResize = () => {
    const divElement = document.getElementById("divGif");

    if (divElement !== null) {
      setGifHeight(divElement.clientHeight);
    }
  };

  useEffect(() => {
    const divElement = document.getElementById("divGif");

    if (divElement !== null) {
      setGifHeight(divElement.clientHeight);
    }
  }, [loadGif]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCloseAlert = () => {
    setFileLargeError(false);
    setSelectedFile();
    setPreview(undefined);
  };

  const uploadContent = (file) => {
    if (!file) return;

    const storageRef = ref(storage, `content/${Date.now()}`);
    const task = uploadBytesResumable(storageRef, file);
    setUploadTask(task);
    setLoadingContent(true);

    task.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(task.snapshot.ref).then((downloadURL) => {
          setContentUrl(downloadURL);
          setUploadBlur(true);
          setLoadingContent(false);
          if( inputRef.current.value) {
            setWithOutFileName(true);
          }
        });
      }
    );
  };

  const cancelUpload = () => {
    if (uploadTask) {
      uploadTask.cancel(); 
      setLoadingContent(false);
      setProgress(0);
    }
  };

  const handleClick = (id) => {
    if (isGuest) {
      setShowGuestPopUp(true);
    } else {
      setHeartActive(id);
    }
  };

  const getHeartPackage = async () => {
    api
      .get(`${Url}/heartPackages/list?limit=200&tag=post`)
      .then((res) => res.data)
      .then(
        (result) => {
          setLoader(false);
          const jsonFree = {
            noOfHearts: 0,
            pricePlan: "Free",
          };
          var arrPackage = result?.data?.value.slice();
          arrPackage.splice(0, 0, jsonFree);
          setPackages(arrPackage);
        },
        (error) => {}
      );
  };

  const resetInput = () => {
    if (inputRef.current) {
        inputRef.current.value = '';
    }
  };
  
  const onChangeFile = (e) => {
    if( inputRef.current.value === "") {
      setWithOutFileName(false);
    }
    getHeartPackage();
    setHidePackage(false);
    setUploadBlur(false);

    if (!e.target.files || e.target.files.length === 0) {
      return e.target.value = null;;
    } else if (e.target.files[0].type.includes("image/")) {
    } else if (e.target.files[0].type.includes("video/")) {
    } else {
      uploadContent("");
      setContentUrl("");
      setSelectedFile("");
      setIsDialogboxOpen(true);
      setStatusMessage(
        "File format not supported. Please, check support page for details on the accepted formats."
      );
      return;
    }

    if (e.target.files[0].size / 1000000 > 80) {
      setContentUrl("");
      setSelectedFile("");
      setFileLargeError(true);
      return;
    }

    if (e.target.files[0].type.includes("image/")) {
      setType(1);
    } else {
      setType(2);
    }

    setContentUrl("");

    const file = e.target.files[0];
    setSelectedFile(file);
    uploadContent(file);
  };

  const handleCloseDialog = () => {
    setType(0);
    setSearchQuery("");
    setIsDialogboxOpen(false);
  };

  useEffect(() => {
    getHeartPackage();
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleCancel = () => {
    setOpen(false);
    // setGifImg("");
    // setType(0);
    // setSelectedFile();
    // if (inputRef.current) {
    //   inputRef.current.value = '';
    // }
  };

  const setGif = async (gif) => {
    setHidePackage(true);
    setType(3);
    document.getElementById("fileInput").value = null;
    setPreview(`https://media.giphy.com/media/${gif.id}/giphy.gif`);
    setContentUrl(`https://media.giphy.com/media/${gif.id}/giphy.gif`);
  };

  const onGifClick = (gif) => {
    setGifImg(gif);
    setOpen(true);
  };

  const onApproveGif = () => {
    setGifConfirmation(true);
    setGif(gifImg);
    toggleGifGrid();
    setOpen(false);
    setHeartActive({
      noOfHearts: 0,
      pricePlan: "Free",
    });
    setDisabledButtonSend(false);
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    event.target.value != "" ? setType("search") : setType("");
  };

  const setEmoji = (emoji) => {
    if (sendMessage.length < 299) {
      setSendMessage(sendMessage + emoji);
    }
  };

  const sendPost = async () => {
    setIsDialogboxOpen(true);
    setDisabledButtonSend(true);
    setLoader(true);

    type === 0 && setStatusMessage(type0);
    type === 1 && setStatusMessage(type12);
    type === 2 && setStatusMessage(type12);
    type === 3 && setStatusMessage(type3);

    setTimeout(() => {
      setSendMessage("");
      dispatch(UpdateSendAllToggleState(false));
      dispatch(UpdateSendAllTogState(false));
      setIsDialogboxOpen(false);
    }, 2500);

    api
      .post(`${Url}/content/tofollowers`, {
        content: /^[\r\n]*$/.test(sendMessage) || /^[\n ]+$/.test(sendMessage) ? "" : sendMessage.trim(),
        hearts: selectedFile === "" ? 0 : heartActive.noOfHearts,
        uid: userData.userId,
        type: selectedFile === "" && contentUrl === "" ? 0 : type,
        contentURL: contentUrl,
        contentBlur:
          selectedFile === ""
            ? false
            : heartActive.noOfHearts === 0
            ? false
            : true,
        isSafari: browser.getBrowserName() === "Safari" ? true : false,
        expireDays: 0,
      })
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const dynamicStyles = {
    height: isMobile ? `${gifHeight - 98}px` : "350px",
  };

  const disableButtonSend = () => {
    if (disabledButtonSend === true || loadingContent === true) {
      return true;
    }
    return false;
  };

  const eventKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!sendMessage.trim()) {
        event.preventDefault();
      }
    }
  };

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
          {fileLargeError && (
            <LargeSizePopUp handleCloseAlert={handleCloseAlert} />
          )}

          {!(isGuest === true) &&
          (userData.totalFollowers === 0 ||
            userData.totalFollowers === undefined) ? (
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full fixed items-center justify-center modal-desk-send-all-follower-discovery">
              <div className="p-3 flex items-center justify-center flex-col">
                <p className="p-send-all-followers-discovery">
                  Once you have followers, tap this to send a message to
                  everyone that follows you.
                </p>
                <button
                  className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-white button-send-all--followers-discovery"
                  onClick={(e) => {
                    dispatch(UpdateSendAllToggleState(false));
                    dispatch(UpdateSendAllTogState(false));
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          ) : (
            <div className={`bg-white rounded-3xl overflow-hidden shadow-xl transform transition-all w-1/2 items-center justify-center ${isGuest === true ? "modal-desk-send-all-discovery-guest" : "modal-desk-send-all-discovery"}`}>
              <div className={`${(isGuest === true) ? 'md:h-32' : ''} px-3 pt-8 mb-6 flex items-center justify-center flex-col`}>
                {!(isGuest === true) ? (
                  <>
                    <button
                      className="self-start absolute top-4 left-4"
                      onClick={(e) => {
                        dispatch(UpdateSendAllToggleState(false));
                        dispatch(UpdateSendAllTogState(false));
                      }}
                    >
                      <svg
                        className="h-7 w-7 text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <section className="w-full">
                      <div className={`mx-auto grid max-w-screen-2xl ${isIpad ? "grid-cols-1" : "grid-cols-1 md:grid-cols-3"}`}>
                        <div className="flex justify-center md:pt-4 md:pr-3 pt-2 div-file-right-send-all-discovery mb-4">
                          <div className="col-span-1 relative mt-3">
                            <input
                              id="fileInput"
                              type="file"
                              ref={inputRef}
                              onChange={onChangeFile}
                              className={`block pb-5 w-full text-sm text-slate-500
                              file:mr-4 file:py-2 file:px-4 file:cursor-pointer
                              file:rounded-full file:border-0
                              file:text-sm file:font-semibold
                              file:bg-gray-100 file:text-black-70
                              hover:file:bg-gray-200 pt-2 file-send-all`}
                              accept="video/mp4,video/x-m4v,video/*,image/*"
                              />
                            { type === 3 && (
                              <img
                                src={preview}
                                alt=""
                                className="video-send-all-files-discovery mx-auto"
                              />
                            )}
                            {selectedFile && type === 1 &&
                            selectedFile.type.includes("image/") ? (
                              <img
                                src={preview}
                                alt=""
                                className="video-send-all-files-discovery mx-auto"
                              />
                            ) : (
                              type == 2 &&
                              contentUrl && (
                                <video
                                  className="video-send-all-files-discovery mx-auto"
                                  autoPlay="true"
                                  playsInline
                                  loop="true"
                                  controls
                                >
                                  <source src={contentUrl}></source>
                                </video>
                              )
                            )}
                            {progress === 0 || progress === 100 ? (
                              ""
                            ) : (
                              <>
                                {type === 2 && !contentUrl ? (
                                  <div className="loading-content-video-send-all-discovery">
                                    <CircularProgressbar
                                      value={progress}
                                      text={`${progress}%`}
                                    />
                                  </div>
                                ) : (
                                  <div className="loading-content-send-all-discovery">
                                    <CircularProgressbar
                                      value={progress}
                                      text={`${progress}%`}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-span-2 text-center">
                          <div className="px-6 mx-auto max-w-[48rem]">
                            <div className="flex flex-col justify-center items-center">
                              {!hidePackage && (
                                <p className="p-right-send-all-discovery">
                                  Select price for locked content
                                </p>
                              )}
                              <div className="flex justify-between items-center div-right-hearts-send-all-discovery">
                                {!hidePackage &&
                                  packages?.map((data) => (
                                    <div
                                      className={
                                        heartActive.id === data.id
                                          ? "flex flex-col justify-center items-center maped-heard-active"
                                          : isMobile === true
                                          ? "flex flex-col justify-center items-center maped-heard-mobile"
                                          : "flex flex-col justify-center items-center maped-heard"
                                      }
                                      key={data.id}
                                      onClick={(e) => handleClick(data)}
                                    >
                                      {heartActive.id === data.id ? (
                                        <svg
                                          className="w-8 h-8 icon-heart-send-all-discovery"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 1900 1900"
                                          id="Heart"
                                          fill="currentColor"
                                        >
                                          <path
                                            d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                                            fill="currentColor"
                                            className="color000000 svgShape"
                                          ></path>
                                        </svg>
                                      ) : (
                                        <svg
                                          className="w-8 h-8 icon-heart-send-all-discovery"
                                          fill="currentColor"
                                          version="1.1"
                                          id="Capa_1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="-23.59 -23.59 518.88 518.88"
                                          stroke="currentColor"
                                          strokeWidth="15.566133000000002"
                                        >
                                          <g
                                            id="SVGRepo_bgCarrier"
                                            strokeWidth="0"
                                          ></g>
                                          <g
                                            id="SVGRepo_tracerCarrier"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          ></g>
                                          <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <g>
                                              {" "}
                                              <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path>{" "}
                                            </g>{" "}
                                          </g>
                                        </svg>
                                      )}
                                      {data.pricePlan === "Free" ? (
                                        <p className="p-icon-heart-send-all-discovery">
                                          Send
                                        </p>
                                      ) : (
                                        <p className="p-icon-heart-send-all-discovery">
                                          {data.noOfHearts}
                                        </p>
                                      )}
                                      {data.pricePlan === "Free" ? (
                                        <p className="p-icon-heart-send-all-discovery">
                                          {data.pricePlan}
                                        </p>
                                      ) : (
                                        <p className="p-icon-heart-send-all-discovery">
                                          ${data.pricePlan}
                                        </p>
                                      )}
                                    </div>
                                  ))}
                              </div>
                              <div className="flex flex-col justify-end items-end w-full pt-2">
                              <textarea
                                cols="40"
                                rows="6"
                                placeholder="Add description…"
                                value={sendMessage}
                                onChange={(e) => {
                                    const notText = /^[\r\n]*$/.test(e.target.value);
                                    const spaceWhite = /^[\n ]+$/.test(e.target.value);
                                    if (selectedFile || gifConfirmation) {
                                      setDisabledButtonSend(false);
                                    } else {
                                      if (notText || spaceWhite) {
                                          setDisabledButtonSend(true);
                                      } else {
                                          setDisabledButtonSend(false);
                                      }
                                  }
                                    setSendMessage(e.target.value.substring(0, 300));
                                }}
                                onKeyPress={eventKeyPress}
                                className="textarea-send-all-discovery"
                            />
                                <div>
                                  <p>{sendMessage.length}/300</p>
                                </div>
                              </div>
                              <div className="flex flex-row justify-end items-center w-full">
                                <EmojiPicker
                                  onEmoji={(emoji) => {
                                      setSendMessage(prevMessage => prevMessage + emoji);
                                      setDisabledButtonSend(false);
                                  }}
                                  disabled={false}
                              />
                                <div className="relative">
                                  <button
                                    type="button"
                                    disabled={false}
                                    onClick={() => {
                                      toggleGifGrid();
                                      setDisabledButtonSend(true);
                                    }}
                                    className="btn-upload-gif"
                                  >
                                    <img
                                      alt="Upload GIF"
                                      src={UpladGifSVG}
                                      className="img-gif"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {uploadLoader ? (
                          <button
                            disabled
                            className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-white button-upload-send-all-discovery disabled:opacity-70"
                          >
                            Uploading
                          </button>
                        ) : (
                          <div className="w-full pb-2 flex flex-col items-center">
                            <div className="flex items-center mb-4"> {/* Added margin-bottom */}
                              <img className="w-6 mr-2" src={warning} alt="Warning" /> {/* Added margin-right */}
                              <p className="text-sm text-gray-600">Posting inappropriate content will lead to immediate removal and possible account suspension. Respect our app community guidelines.</p>
                            </div>

                            {withOutFileName && (
                              <button
                                className="inline-block rounded-lg bg-blue-500 disabled:opacity-80
                                px-3 py-2 text-white button-upload-send-all-discovery"
                                onClick={() =>
                                  sendMessage === "" &&
                                  contentUrl === "" &&
                                  uploadBlur === false
                                    ? ""
                                    : sendPost()
                                }
                                disabled={disableButtonSend()}
                              >
                                Send to all followers
                              </button>
                            )}
                          </div>

                        )}
                    </section>
                  </>
                ) : (
                  <>
                    <button
                      className="absolute left-1 top-1 m-4 sm:top-0"
                      onClick={(e) => {
                        dispatch(UpdateSendAllToggleState(false));
                        dispatch(UpdateSendAllTogState(false));
                      }}
                    >
                      <svg
                        className="h-7 w-7 text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <div className="flex items-center justify-center div-guest-send-all-discovery">
                      <p>
                        This feature is not available for guest user.
                        <button
                          onClick={() => {
                            dispatch(
                              UpdateOnlineUser({
                                online: false,
                              })
                            ).then(() => {
                              guestUserLogout();
                            });
                          }}
                        >
                          Create an account or Sign in
                        </button>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <GuestToastBar
          isOpened={isDialogboxOpen}
          handleClose={handleCloseDialog}
          isOnlyMsg
          message={networkStatus !== "online" ? "Please check your internet connection and retry" : statusMessage}
        />

        <canvas
          ref={canvasRef}
          width={300}
          height={300}
          style={{ display: "none" }}
        />
      </div>

      {showGrid && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
            <div
              id="divGif"
              className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full fixed items-center justify-center modal-desk-send-all-gifs-discovery gif-modal"
            >
              <div className="mt-4 flex items-center justify-center flex-col">
                <button
                  className="absolute top-2 left-2"
                  onClick={(e) => {
                    toggleGifGrid();
                    // setType("");
                    setSearchQuery("");
                    setLoadGid(false);
                    setDisabledButtonSend(false);
                    // handleCancel(); 
                  }}
                >
                  <svg
                    className="h-7 w-7 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <input
                  className="w-3/5 border rounded-lg border-gray-200 p-2 text-sm shadow-sm left-2 h-9 mb-2"
                  type="text"
                  placeholder="Search for GIF's"
                  value={searchQuery}
                  onChange={handleChange}
                />
                <div
                  style={dynamicStyles}
                  className="flex justify-center gif-modal overflow-auto w-full max-h-[300px]"
                >
                  <GifModal
                    type={type}
                    searchText={searchQuery}
                    setLoadGid={setLoadGid}
                    onGifClick={(gif, e) => {
                      // setSelectedFile("");
                      e.preventDefault();
                      onGifClick(gif);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {open && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full fixed items-center justify-center modal-desk-send-all-gifs-conf-discovery">
              <div className="p-3 flex items-center justify-center flex-col">
                <img
                  className="h-auto w-[180px]"
                  src={`https://media.giphy.com/media/${gifImg.id}/giphy.gif`}
                  alt="...loading"
                />
                <p className="gif-confirm-title">
                  Do you want to send this GIF?
                </p>
                <div className="flex flex-row justify-center items-center w-full">
                  <button
                    className="btn-gif-confirm-cancel"
                    autoFocus
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-gif-confirm"
                    onClick={() => {
                      onApproveGif();
                      cancelUpload();
                      resetInput();
                      setWithOutFileName(true);
                    }}
                    autoFocus
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SendAllModal;
